import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfslocatiegegevensAntwoorden } from "./bedrijfslocatiegegevens-antwoorden";
import { ContactpersoonAntwoorden } from "./contactpersoon-antwoorden";

export class WerkgeverLocatieAntwoorden extends AntwoordBlok {
  constructor(
    public datumOngeval: Antwoord<string>,
    public tijdstipOngeval: Antwoord<string>,
    public bekendWelkeWerkgever: Antwoord<string>,
    public bedrijfslocatiegegevens: BedrijfslocatiegegevensAntwoorden | null,
    public contactpersoonGelijkAanMelder: Antwoord<string>,
    public contactpersoon: ContactpersoonAntwoorden | null,
    public functie: Antwoord<string>,
    public plaatsOngeval: Antwoord<string>,
    public locatiegegevens: BedrijfslocatiegegevensAntwoorden | null
  ) {
    super();
  }

  static initialize(): WerkgeverLocatieAntwoorden {
    return new WerkgeverLocatieAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      BedrijfslocatiegegevensAntwoorden.initialize(),
      new Antwoord<string>(""),
      ContactpersoonAntwoorden.initialize(),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      BedrijfslocatiegegevensAntwoorden.initialize()
    );
  }

  static fromJson(json: any): WerkgeverLocatieAntwoorden {
    return new WerkgeverLocatieAntwoorden(
      Antwoord.fromJson(json.datumOngeval),
      Antwoord.fromJson(json.tijdstipOngeval),
      Antwoord.fromJson(json.bekendWelkeWerkgever),
      BedrijfslocatiegegevensAntwoorden.tryGetFromJson(json.bedrijfslocatiegegevens),
      Antwoord.fromJson(json.contactpersoonGelijkAanMelder),
      ContactpersoonAntwoorden.fromJson(json.contactpersoon),
      Antwoord.fromJson(json.functie),
      Antwoord.fromJson(json.plaatsOngeval),
      BedrijfslocatiegegevensAntwoorden.tryGetFromJson(json.locatiegegevens)
    );
  }
}
