import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { BedrijfslocatiegegevensVragen } from "./bedrijfslocatiegegevens-vragen";
import { ContactpersoonVragen } from "./contactpersoon-vragen";

export class WerkgeverLocatieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public datumOngeval: Vraag,
    public tijdstipOngeval: Vraag,
    public gegevensWerkgeverBrzoInrichtingTitel: Tekst,
    public bekendWelkeWerkgever: Meerkeuzevraag,
    public bedrijfslocatiegegevens: BedrijfslocatiegegevensVragen,
    public contactpersoonGelijkAanMelder: Meerkeuzevraag,
    public contactpersoonTitel: Tekst,
    public contactpersoon: ContactpersoonVragen,
    public functie: Vraag,
    public plaatsOngeval: Meerkeuzevraag,
    public locatieTitel: Tekst,
    public locatiegegevens: BedrijfslocatiegegevensVragen,
    public ongevalInBuitenlandToelichtingParagraaf: Tekst
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): WerkgeverLocatieVragen {
    return new WerkgeverLocatieVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toDatumVraag(`${key}.datumOngeval`, json.datumOngeval),
      jsonHelpers.toTijdVraag(`${key}.tijdstipOngeval`, json.tijdstipOngeval),
      jsonHelpers.toTitel(`${key}.gegevensWerkgeverBrzoInrichtingTitel`, json.gegevensWerkgeverBrzoInrichtingTitel),
      jsonHelpers.toMeerkeuzeVraag(`${key}.bekendWelkeWerkgever`, json.bekendWelkeWerkgever),
      BedrijfslocatiegegevensVragen.fromJson(`${key}.bedrijfslocatiegegevens`, json.bedrijfslocatiegegevens),
      jsonHelpers.toMeerkeuzeVraag(`${key}.contactpersoonGelijkAanMelder`, json.contactpersoonGelijkAanMelder),
      jsonHelpers.toTitel(`${key}.contactpersoonTitel`, json.contactpersoonTitel),
      ContactpersoonVragen.fromJson(`${key}.contactpersoon`, json.contactpersoon),
      jsonHelpers.toTekstVraag(`${key}.functie`, json.functie),
      jsonHelpers.toMeerkeuzeVraag(`${key}.plaatsOngeval`, json.plaatsOngeval),
      jsonHelpers.toTitel(`${key}.locatieTitel`, json.locatieTitel),
      BedrijfslocatiegegevensVragen.fromJson(`${key}.locatiegegevens`, json.locatiegegevens),
      jsonHelpers.toParagraaf(`${key}.ongevalInBuitenlandToelichtingParagraaf`, json.ongevalInBuitenlandToelichtingParagraaf)
    );
  }
}
